<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.mail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
            rules="required"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <!-- <b-form-input
                id="user-role"
                v-model="userData.Role"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              /> -->
              <v-select
                v-model="userData.role"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status-->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group
              label="Status"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.isEnabled"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="password"
            vid="password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password-new"
            >
              <b-input-group
                class="input-group-merge"
                :class="validationContext.errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password-new"
                  v-model="userData.password"
                  :type="passwordFieldTypeNew"
                  :state="getValidationState(validationContext)"
                  class="form-control-merge"
                  name="password-new"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconNew"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Confirm Password -->
          <validation-provider
            #default="validationContext"
            name="Confirm password"
            rules="required|confirmed:password"
          >
            <b-form-group
              label="Confirm password"
              label-for="password-confirm"
            >
              <b-input-group
                class="input-group-merge"
                :class="validationContext.errors.length > 0 ? 'is-invalid':null"
              >
                  <b-form-input
                    id="password-confirm"
                    v-model="userData.confirm_password"
                    :type="passwordFieldTypeRetype"
                    class="form-control-merge"
                    :state="getValidationState(validationContext)"
                    name="password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconRetype"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, computed, getCurrentInstance } from 'vue'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '@axios'
import useUsersList from './useUsersList'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const passwordFieldTypeNew = ref('password')
    const passwordFieldTypeRetype = ref('password')

    const vm = getCurrentInstance().proxy

    const { roleOptions } = useUsersList()

    const statusOptions = [
      { label: 'Activo', value: 1 },
      { label: 'Inactivo', value: 0},
    ]

    const blankUserData = {
      name: '',
      username: '',
      mail: '',
      role: '',
      phone: '',
      isEnabled: 0,
      password: '',
      confirm_password: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      axios
      .post(`${process.env.VUE_APP_BASE_URL}/add_user`, 
        { data: userData.value }
      )
      .then(() => {
        vm.$swal({
          title: 'Usuario registrado!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: ()=> {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          }
        }) 
      })
      .catch(error => {
        console.log(error.response)
        vm.$swal({
          title: 'Error al registrar usuario!',
          text: `Error: ${error.response.data.error}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    // computed
    const passwordToggleIconNew = computed( () => {
      return passwordFieldTypeNew.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    const passwordToggleIconRetype = computed( () => {
      return passwordFieldTypeRetype.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    // methods
    const togglePasswordNew = () => {
      passwordFieldTypeNew.value = passwordFieldTypeNew.value === 'password' ? 'text' : 'password'
    }
    const togglePasswordRetype = () => {
      passwordFieldTypeRetype.value = passwordFieldTypeRetype.value === 'password' ? 'text' : 'password'
    }

    return {
      userData,
      onSubmit,
      statusOptions,
      roleOptions,

      passwordFieldTypeNew,
      passwordFieldTypeRetype,

      passwordToggleIconNew,
      passwordToggleIconRetype,

      togglePasswordNew,
      togglePasswordRetype,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
