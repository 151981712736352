import { ref, watch, computed } from 'vue'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'User', sortable: true },
    { key: 'mail', label: 'Email', sortable: true },
    { key: 'role', sortable: false  },
    { key: 'status', sortable: false  },
    { key: 'actions', label: 'Actions', sortable: false  },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('userID')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    axios
      .post(`${process.env.VUE_APP_BASE_URL}/user_info`, {
        data: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
        }
      })
      .then(response => {
        const { users, total } = response.data.result

        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const roleOptions = [
    // { label: 'Admin', value: 'Admin' },
    { label: 'Finanzas', value: 'Finanzas' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Shopper', value: 'Shopper' },
    { label: 'Supply', value: 'Supply' },
    { label: 'Ventas', value: 'Ventas' },
    { label: 'Country Manager', value: 'Country Manager' },
  ]

  const resolveUserStatusVariant = status => {
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'
    return 'primary'
  }

  const resolveUserStatus = status => {
    if (status === 1) return 'Activo'
    if (status === 0) return 'Inactivo'
    return 'Desactivado'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Admin') return 'SettingsIcon'
    return 'UserIcon'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    roleOptions,

    resolveUserStatusVariant,
    resolveUserStatus,
    resolveUserRoleIcon,
    refetchData,
  }
}
